import React from 'react'
import { VendorSearchState, tagGroupNames } from '../states/vendor-list'
import TagFilters from './tag-filters'
import VendorFilterSort from './sort'
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useFeatureFlag, SPLITS } from '@/helpers/useFeatureFlag'
import { Close } from '@mui/icons-material'

type Props = {
  filterOpen: boolean
  toggleFilterOpen: () => void
  filters: VendorSearchState['filters']
  search: VendorSearchState['search']
  stats: VendorSearchState['stats']
  searching: boolean
}

const VendorFilters: React.FC<Props> = ({
  filterOpen,
  toggleFilterOpen,
  search,
  filters,
  searching,
  stats,
}) => {
  const countrySpecificFiltersFlagOn = useFeatureFlag(SPLITS.COUNTRY_SPECIFIC_FILTERS_GM)
  const showDiversityTags = useFeatureFlag(SPLITS.SHOW_DIVERSITY_TAGS_GM)
  const theme = useTheme()

  const clearAll = () => {
    void search({
      [tagGroupNames.Occasion.key]: [],
      [tagGroupNames.Cuisine.key]: [],
      [tagGroupNames.Dietary.key]: [],
      [tagGroupNames.KeyDates.key]: [],
      [tagGroupNames.DiversityOfOwnership.key]: [],
      highlightsTags: [],
    })
  }

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  if (isSmallScreen && !filterOpen) return null
  const TagSections = (
    <>
      <Stack spacing={1} direction="column">
        <Typography color="text.secondary">Sort by</Typography>
        <VendorFilterSort filters={filters} search={search} />
      </Stack>
      <Stack paddingTop={2} top={0} position="sticky" spacing={1} direction="column">
        <Stack justifyContent="space-between" alignItems="center" direction="row">
          <Typography fontWeight={500} variant="subtitle2">
            Filters
          </Typography>
          <Button onClick={() => clearAll()} size="small" variant="text">
            Clear all
          </Button>
        </Stack>
        <TagFilters
          search={search}
          searching={searching}
          stats={stats}
          filters={filters}
          tagType={tagGroupNames.Occasion}
          defaultOpen={false}
          specificToCountry={countrySpecificFiltersFlagOn}
          orderBy={countrySpecificFiltersFlagOn ? 'position' : 'label'}
        />
        <TagFilters
          search={search}
          searching={searching}
          stats={stats}
          filters={filters}
          tagType={tagGroupNames.Cuisine}
          defaultOpen={false}
          specificToCountry={countrySpecificFiltersFlagOn}
          orderBy="position"
        />
        <TagFilters
          search={search}
          searching={searching}
          stats={stats}
          filters={filters}
          tagType={tagGroupNames.Dietary}
          defaultOpen={false}
          orderBy="label"
          useName
        />
        <TagFilters
          search={search}
          searching={searching}
          stats={stats}
          filters={filters}
          tagType={tagGroupNames.KeyDates}
          defaultOpen={false}
          specificToCountry={countrySpecificFiltersFlagOn}
          orderBy={countrySpecificFiltersFlagOn ? 'position' : 'label'}
        />
        {showDiversityTags ? (
          <TagFilters
            search={search}
            searching={searching}
            stats={stats}
            filters={filters}
            tagType={tagGroupNames.DiversityOfOwnership}
            defaultOpen={false}
            specificToCountry={countrySpecificFiltersFlagOn}
            orderBy={countrySpecificFiltersFlagOn ? 'position' : 'label'}
          />
        ) : null}
      </Stack>
    </>
  )
  if (isSmallScreen && filterOpen) {
    return (
      <Dialog open fullScreen>
        <IconButton
          onClick={toggleFilterOpen}
          sx={{ width: 'fit-content', position: 'fixed', right: 0 }}
        >
          <Close />
        </IconButton>
        <Box px={2} py={1}>
          {TagSections}
        </Box>
      </Dialog>
    )
  }
  return TagSections
}

export default VendorFilters
