import { useTheme } from 'styled-components'
import React, { useEffect, useState } from 'react'
import Icon from '@caterdesk/ui--icon'
import { useAppState } from '../states/app'
import { useAuthState } from '../states/auth'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import AddressLookup from '../input-bar/address-lookup'
import { StyledText, ButtonWrapper } from '../input-bar/styles'
import { UserBasketSettingsLocationFragment } from '@/generated/graphql'
import { VendorSearchState } from '../states/vendor-list'
import { AddressErrors } from '../../types/enums/Errors.enum'
import { UserBasketSettings } from '../../domain/user-basket-settings'
import {
  Button,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  MenuItem,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme as useMuiTheme,
  Dialog,
  DialogContent,
} from '@mui/material'
import Link, { useRouter } from '@/components/Link'
import {
  AccessTime,
  CalendarToday,
  EditCalendar,
  InfoRounded,
  LocationOn,
  Search,
  Tune,
} from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useAddressLabel } from '../../helpers/useAddressLabel'

const CustomIconForDatepicker = () => {
  return <EditCalendar fontSize="small" />
}

type Props = {
  userBasketSettings: UserBasketSettings
  minAllowedDate: Date
  timeSlots: string[]
  onUserBasketSettingsChange: (settings: UserBasketSettings) => void
  called: boolean
  search: VendorSearchState['search']
  filterCount: VendorSearchState['filterCount']
  toggleFilterOpen: () => void
  filters: VendorSearchState['filters']
}

const VendorSearchBar: React.FC<Props> = ({
  userBasketSettings,
  timeSlots,
  minAllowedDate,
  onUserBasketSettingsChange,
  called,
  search,
  filterCount,
  toggleFilterOpen,
  filters,
}) => {
  const router = useRouter()
  const theme = useTheme()
  const muiTheme = useMuiTheme()
  const authState = useAuthState()
  const { errors, setErrors, updateUserState, userState } = useAppState()
  const [modalOpen, toggleModal] = useState(false)
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down('md'))
  const { addressLabel: pinAddress, showFullAddress } = useAddressLabel(userBasketSettings.location)

  useEffect(() => {
    if (!userBasketSettings.initalised) return
    if (!userBasketSettings.location) toggleModal(true)
    setErrors({})
  }, [userBasketSettings.initalised])

  useEffect(() => {
    if (pinAddress) toggleModal(false)
  }, [pinAddress])

  const closeModal = () => {
    toggleModal(Boolean(!userBasketSettings.location || errors.address))
    if (!userBasketSettings.location) setErrors({ address: AddressErrors.INVALID_ADDRESS })
  }

  const handleAddressChange = (location: UserBasketSettingsLocationFragment) => {
    updateUserState({ location })
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {called ? (
        <Stack
          position="relative"
          spacing={1}
          useFlexGap
          flexWrap="wrap"
          direction="row"
          alignItems="center"
        >
          <Typography
            display={{ xs: 'none', sm: 'initial' }}
            width={{ xs: '100%', lg: 'unset' }}
            color="text.secondary"
          >
            Ordering for
          </Typography>
          <TextField
            fullWidth
            onClick={() => toggleModal(true)}
            size="small"
            placeholder="Deliver to"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOn color="primary" />
                </InputAdornment>
              ),
            }}
            value={pinAddress}
            sx={{
              width: {
                xs: '100%',
                xl: showFullAddress ? '25%' : '180px',
                lg: showFullAddress ? '19%' : '180px',
                sm: showFullAddress ? '25%' : '180px',
              },
              flexGrow: { xs: 1, md: 'unset' },
            }}
          />
          <DatePicker
            sx={{
              width: { xs: '50%', sm: '170px', md: '170px', lg: '180px' },
              flexGrow: { xs: 1, md: 'unset' },
            }}
            slots={{
              openPickerIcon: CustomIconForDatepicker,
            }}
            slotProps={{
              textField: {
                size: 'small',
                fullWidth: true,
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarToday color="primary" />
                    </InputAdornment>
                  ),
                },
              },
            }}
            format="dd/MM/yy"
            minDate={minAllowedDate}
            value={userState.date ? new Date(userState.date) : null}
            onChange={(newDate) => {
              if (!newDate) return
              updateUserState({ date: newDate.getTime() })
            }}
            disabled={!userState.location}
          />
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            width={{ xs: '48%', sm: '210px', md: '210px' }}
          >
            <TextField
              sx={{ flexGrow: 1 }}
              select
              size="small"
              disabled={!userBasketSettings.date}
              value={userBasketSettings.time ?? null}
              SelectProps={{
                displayEmpty: true,
                renderValue: (value) => <>{(value as string) ?? 'Time'}</>,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccessTime color="primary" />
                  </InputAdornment>
                ),
              }}
              onChange={(event) =>
                onUserBasketSettingsChange({ ...userBasketSettings, time: event.target.value })
              }
            >
              {timeSlots.map((timeslot) => (
                <MenuItem key={timeslot} value={timeslot}>
                  {timeslot}
                </MenuItem>
              ))}
            </TextField>
            <Tooltip title="We suggest selecting a delivery window that starts 45 min before your event. e.g. If your event starts at 1pm, select 12:15-12:45 as your delivery window. This should ensure enough time for delivery, collection & setup before your event starts.">
              <InfoRounded sx={{ color: 'text.primary', fontSize: '16px' }} />
            </Tooltip>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            flexGrow={1}
            paddingRight={{ xs: 1, md: 0 }}
            alignItems="center"
            justifyContent="flex-end"
          >
            <TextField
              sx={{ minWidth: '300px', flexGrow: { xs: 1, md: 0 } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => search({ search: e.target.value })}
              value={filters.search || ''}
              size="small"
              placeholder="Search by vendor or keyword"
            />
            {isSmallScreen ? (
              <>
                <IconButton onClick={toggleFilterOpen}>
                  <Tune />
                </IconButton>
                <Typography>{filterCount}</Typography>
              </>
            ) : null}
          </Stack>
        </Stack>
      ) : null}

      <Dialog open={modalOpen} onClose={closeModal} fullWidth maxWidth="md">
        <DialogContent
          sx={{
            py: 3,
          }}
        >
          <Stack direction="column" spacing={2} alignItems="center">
            <Icon icon="locationPin" size={54} fill={theme.colors.primary} />
            <Typography variant="h3">Where are you delivering to?</Typography>
            <AddressLookup
              error={errors.address}
              selectedLocation={userState.location}
              onAddressChanged={handleAddressChange}
              availableAccountLocations={
                authState.type === 'authenticated' ? (authState.user.account?.locations ?? []) : []
              }
            />
            <ButtonWrapper>
              <Button variant="contained" fullWidth onClick={closeModal}>
                Confirm
              </Button>
            </ButtonWrapper>
            {authState.type !== 'authenticated' && (
              <StyledText style={{ fontSize: theme.fontSizes.s, marginTop: 8 }}>
                Delivering to a recent address?{' '}
                <Link href={`/authentication/login?destination=${router.asPath}`} passHref>
                  <Button>Sign in</Button>
                </Link>
              </StyledText>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </LocalizationProvider>
  )
}

export default VendorSearchBar
