import React from 'react'
import { useMedia } from 'react-use'
import { useTheme } from 'styled-components'
import Link from '@/components/Link'
import { sortBy } from 'lodash'
import { TagWrapper } from './index.styles'
import Icon from '@caterdesk/ui--icon'
import { mobileMediaQuery } from '@caterdesk/ui--theme'
import { VendorsGmQuery } from '@/generated/graphql'
import Image from 'next/image'
import { Chip, Typography, Stack, Divider, Card, Grid, Tooltip } from '@mui/material'
import { type Currency, getCurrencySymbol } from '@caterdesk/utils--money'
import VendorRating from '../vendor-rating'

export type Props = {
  vendor: NonNullable<VendorsGmQuery['vendors']['rows']>[number]
}

const VendorCard: React.FC<Props> = ({ vendor }) => {
  const theme = useTheme()
  const isMobileScreen = useMedia(mobileMediaQuery, false)
  if (!vendor.permalink) return null
  const priceLevel = [...Array(3)].map((_, i) => {
    const priceLevelAmount = vendor.priceLevel || 0
    const fill = i < priceLevelAmount ? theme.colors.text : theme.colors.text_muted

    return (
      <Chip
        key={i}
        label={getCurrencySymbol((vendor.currency?.toUpperCase() as Currency) ?? 'GBP')}
        size="small"
        style={{ backgroundColor: fill }}
      />
    )
  })
  const vendorImage = sortBy(vendor.images || [], 'position')
  const vendorTags = (vendor.tags || [])
    .filter((tag) => tag?.label)
    .map((tag) => tag?.label)
    .join(', ')

  return (
    <Link href={`/office-catering/vendors/${vendor.permalink}/`}>
      <Card sx={{ height: '100%' }}>
        <Grid container height="100%">
          <Grid position="relative" item xs={12} lg={5} xl={4}>
            {vendorImage.length > 0 && vendorImage[0]?.secureUrl && (
              <Stack
                justifyContent="center"
                alignItems="center"
                height={{ lg: '100%', xs: '200px' }}
                width="100%"
              >
                <Image
                  objectFit="cover"
                  alt={vendor.companyName}
                  src={vendorImage[0].secureUrl}
                  fill
                />
                {vendor.logo && (
                  <Image
                    src={vendor.logo}
                    width={77}
                    height={77}
                    alt=""
                    style={{
                      zIndex: 1,
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                )}
              </Stack>
            )}
          </Grid>
          <Grid item xs={12} lg={7} xl={8}>
            <Stack direction="column" width="100%" height="100%" justifyContent="space-between">
              <Stack spacing={2} paddingX={3} paddingY={2} direction="column">
                <Typography variant="subtitle2">{vendor.companyName}</Typography>
                <Typography color="text.secondary" variant="body2">
                  {vendor.descriptionShort}
                </Typography>
              </Stack>
              <Stack direction="column">
                <Divider />
                <Stack spacing={2} alignItems="center" paddingX={3} paddingY={1} direction="row">
                  <Stack direction="row" spacing={0.5}>
                    {priceLevel}
                  </Stack>
                  {(vendor.rating || 0) >= 2 && (
                    <VendorRating
                      justify="flex-end"
                      rating={vendor.rating || 0}
                      totalRatings={vendor.totalRatings || 0}
                    />
                  )}

                  {vendorTags && (
                    <TagWrapper>
                      <Tooltip title={vendorTags} placement="top">
                        <span>
                          <Icon
                            className="kitchenTag"
                            variation="circle"
                            icon="knifeFork"
                            fill={theme.colors.text_muted}
                            size={isMobileScreen ? 11 : 13}
                          />
                        </span>
                      </Tooltip>
                    </TagWrapper>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Link>
  )
}

export default VendorCard
