import React, { useCallback, useEffect, useState } from 'react'
import { compact } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { basketLocationToAddress } from '@caterdesk/utils--gm-validation'
import {
  AccountLocation,
  Address,
  useLookupAddressLazyQuery,
  UserBasketSettingsLocationFragment,
} from '@/generated/graphql'
import {
  addressToLabelAndDetailLines,
  addressToSingleLine,
  lookupAddressDetails,
  MIN_QUALITY,
  countries,
  isValidCity,
} from '@/components/page-specific/gm/helpers/addresses'
import { PopoverGroupType } from '../popover'
import { NewAddress } from './styles'
import { AddressErrors } from '../../types/enums/Errors.enum'
import { isNotNil } from '../../utils/typing'
import { useFeatureFlag, SPLITS } from '@/components/page-specific/gm/helpers/useFeatureFlag'
import { Autocomplete, InputAdornment, ListItem, ListItemText, TextField } from '@mui/material'
import { useLocale } from '@/components/Link'
import PlaceIcon from '@mui/icons-material/Place'
import { match } from 'ts-pattern'

export type Props = {
  onAddressChanged: (address: UserBasketSettingsLocationFragment) => void
  initialValue?: boolean
  placeholder?: string
  allowAnyCity?: boolean
  error?: string
  availableAccountLocations?: Array<
    | ({
        __typename: 'AccountLocation'
      } & Pick<AccountLocation, 'id' | 'label' | 'address' | 'isBillingAddress'>)
    | null
  >
  selectedLocation: UserBasketSettingsLocationFragment | undefined
}

let timeout: ReturnType<typeof setTimeout> | null = null

const addressPopoverFormat = (addresses: (Address & { index?: number })[], prefix = '') =>
  addresses.map(addressToLabelAndDetailLines).map(({ label, details, index }, i) => ({
    value: `${prefix}${index || i}`,
    children: (
      <>
        <p data-testid="address-search-result-title">{label}</p>
        {details}
      </>
    ),
  }))

const AddressLookup: React.FC<Props> = ({
  initialValue = true,
  placeholder = 'Enter a street address or postcode to continue',
  allowAnyCity = false,
  availableAccountLocations = [],
  selectedLocation,
  error,
  onAddressChanged,
}) => {
  const getLocale = useLocale()
  const locale = getLocale !== 'en-gb' ? getLocale : undefined
  const useNewSavedAddresses = useFeatureFlag(SPLITS.USE_NEW_SAVED_ADDRESSED)
  const [lookupError, setLookupError] = useState('')
  const errorText = lookupError || error
  const showError = Boolean(errorText)
  const [addressInput, setAddressInput] = useState(
    selectedLocation && initialValue
      ? addressToSingleLine(basketLocationToAddress(selectedLocation))
      : '',
  )
  const apolloClient = useApolloClient()

  const [lookupAddress, lookupAddressResult] = useLookupAddressLazyQuery()
  const addressesFound = compact(lookupAddressResult?.data?.lookupAddress?.locations ?? [])
    .filter((location) => {
      const valid =
        location.address &&
        location.relevance >= MIN_QUALITY &&
        location.address.postcode &&
        location.address.street &&
        location.address.city !== location.address.street
      const allowSingaporeCities = !allowAnyCity && location.address.country === 'Singapore'
      const allowAustralianCities = !allowAnyCity && location.address.country === 'Australia'
      return allowAnyCity || allowSingaporeCities || allowAustralianCities
        ? valid
        : valid && isValidCity(location.address.city || '')
    })
    .map(({ address }, index) => ({
      ...address,
      autocompleteType: 'Addresses found' as const,
      label: address.street,
      index,
    }))
  let popoverData: PopoverGroupType[] =
    addressesFound.length > 0 ? [{ items: addressPopoverFormat(addressesFound) }] : []

  const accountLocationData = availableAccountLocations
    .filter(isNotNil)
    .filter((loc) => !loc.isBillingAddress)
  const [showAccountDropdown, setShowAccountDropdown] = useState(
    accountLocationData.length > 0 &&
      (selectedLocation?.__typename === 'AccountLocation' || !selectedLocation),
  )

  useEffect(() => {
    setShowAccountDropdown(
      accountLocationData.length > 0 &&
        (selectedLocation?.__typename === 'AccountLocation' || !selectedLocation),
    )
  }, [selectedLocation, accountLocationData.length])

  const accountLocationAddresses = accountLocationData
    .map(({ label, address }, index) => ({
      label,
      ...address,
      index,
      autocompleteType: 'Saved addresses' as const,
    }))
    .filter(({ label, ...address }) =>
      addressToSingleLine({ label, ...address })
        .toLowerCase()
        .includes(addressInput.toLowerCase()),
    )

  if (useNewSavedAddresses) {
    const searchResults =
      popoverData.length > 0 ? { ...popoverData[0], title: 'Addresses found' } : null
    popoverData = []
    if (accountLocationAddresses.length > 0) {
      popoverData = [
        {
          title: 'Saved addresses',
          items: addressPopoverFormat(accountLocationAddresses, 'account-'),
        },
      ]
    } else if (showAccountDropdown) {
      popoverData = [
        {
          items: [{ value: '', children: 'No saved addresses match' }],
        },
      ]
    }
    if (showAccountDropdown) {
      popoverData.push({
        items: [{ value: 'new', children: <NewAddress>Search for new address</NewAddress> }],
      })
    } else if (searchResults) {
      popoverData.push(searchResults)
    }
  }

  if (!useNewSavedAddresses && accountLocationAddresses.length > 0) {
    popoverData = [
      {
        title: 'Your saved addresses',
        items: addressPopoverFormat(accountLocationAddresses, 'account-'),
      },
      ...popoverData,
    ]
  }

  if (
    lookupError === AddressErrors.INVALID_ADDRESS &&
    lookupAddressResult?.data?.lookupAddress &&
    addressesFound.length > 0
  ) {
    setLookupError('')
  }
  if (
    !lookupError &&
    lookupAddressResult?.data?.lookupAddress &&
    addressInput.length > 2 &&
    !addressesFound.length
  ) {
    setLookupError(AddressErrors.INVALID_ADDRESS)
  }

  const lookupAddressChange = useCallback(
    // @ts-expect-error unknown
    (query) => {
      if (timeout) clearTimeout(timeout)
      if (!query) return
      timeout = setTimeout(() => {
        lookupAddress({
          variables: { query, countries, useGoogle: true, allowAnyCity, locale: locale || null },
        }).catch(null)
      }, 500)
    },
    [addressInput],
  )

  const inputTextChange = (value: string) => {
    setAddressInput(value)
    lookupAddressChange(value)
    if (lookupError) {
      setLookupError('')
    }
  }

  const handleAddressSelected = async (value?: string) => {
    if (!value) {
      setLookupError(AddressErrors.INVALID_ADDRESS)
      return
    }
    if (value === 'new') {
      setShowAccountDropdown(false)
      return
    }
    let basketLocation = value.startsWith('account-')
      ? accountLocationData[parseInt(value.split('-')[1], 10)]
      : addressesFound[parseInt(value, 10)]
    const address = basketLocationToAddress(basketLocation)
    let { latitude, longitude } = address
    if (basketLocation.__typename === 'AccountLocation' && (!latitude || !longitude)) {
      const addressFound = await lookupAddressDetails(address, apolloClient, true, locale)
      if (addressFound) {
        latitude = addressFound.latitude
        longitude = addressFound.longitude
        basketLocation = {
          ...basketLocation,
          address: { ...basketLocation.address, latitude, longitude },
        }
        setShowAccountDropdown(true)
      }
    }
    if (!latitude || !longitude) {
      setLookupError(AddressErrors.INVALID_ADDRESS)
      return
    }
    setLookupError('')
    onAddressChanged(basketLocation)
    setAddressInput(addressToSingleLine(address))
  }

  return (
    <Autocomplete
      size="small"
      options={lookupAddressResult.loading ? [] : [...accountLocationAddresses, ...addressesFound]}
      getOptionLabel={(option) => option.label || ''}
      filterOptions={(x) => x}
      loading={lookupAddressResult.loading}
      groupBy={(option) => option.autocompleteType}
      onInputChange={(_, value, reason) =>
        ['input', 'clear'].includes(reason) ? inputTextChange(value) : null
      }
      inputValue={addressInput}
      onChange={(_, value) => {
        match(value)
          .with({ autocompleteType: 'Saved addresses' }, (v) =>
            handleAddressSelected(`account-${v.index}`),
          )
          .with({ autocompleteType: 'Addresses found' }, (v) =>
            handleAddressSelected(v.index.toString()),
          )
          .with(null, () => {})
          .exhaustive()
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          autoComplete="off"
          error={showError}
          helperText={errorText}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <PlaceIcon color="primary" />
              </InputAdornment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            'data-dd-privacy': 'allow',
            'data-testid': 'gm-address-input',
          }}
        />
      )}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props
        return (
          <ListItem key={key} component="li" {...optionProps}>
            {match(option)
              .with({ autocompleteType: 'Saved addresses' }, () => (
                <ListItemText
                  primary={option.label}
                  secondary={addressToSingleLine({ ...option, label: null })}
                />
              ))
              .with({ autocompleteType: 'Addresses found' }, () => (
                <ListItemText primary={addressToSingleLine({ ...option, label: null })} />
              ))
              .exhaustive()}
          </ListItem>
        )
      }}
      sx={{
        mb: 2,
      }}
    />
  )
}

export default AddressLookup
