import * as dateFns from 'date-fns'
import { computeDeliveryTimeSlots, UserBasketSettings } from '../../domain/user-basket-settings'
import { Settable } from '../../utils/typing'
import { addDurationToDateConsideringSomeDays } from '@caterdesk/utils--gm-validation'
import { useUserBasketSettingsState } from './useUserBasketSettingsState'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'

type State = {
  userBasketSettings: Settable<UserBasketSettings>
  minAllowedDeliveryDate: Date
  deliveryTimeSlots: string[]
}

export const useVendorListPageState = (): State => {
  const splitOn = useFeatureFlag(SPLITS.SAME_DAY_GM_DELIVERY)
  const cutOff5pm = useFeatureFlag(SPLITS.GM_CUTOFF_5PM)
  let minHours = 24
  if (splitOn) minHours = 2
  if (cutOff5pm) {
    const now = new Date()
    const currentHour = now.getHours()
    minHours = currentHour >= 17 ? 48 : 24
    if (dateFns.isWeekend(now)) {
      const dayOfWeek = dateFns.getDay(now)
      minHours += 24 * dayOfWeek === 0 ? 2 : 1
    }
    minHours -= currentHour
  }
  const minAllowedDeliveryDate = addDurationToDateConsideringSomeDays(
    new Date(),
    { hours: minHours },
    (date) => {
      if (dateFns.isWeekend(date)) return false

      const deliveryTimeSlots = computeDeliveryTimeSlots({
        deliveryDate: date,
        minAllowedDateTime: null,
        useDeliveryTimes: cutOff5pm,
      })

      return deliveryTimeSlots.length > 0
    },
  )

  const userBasketSettingsState = useUserBasketSettingsState({
    resetDateTimeBefore: { date: minAllowedDeliveryDate },
  })

  const deliveryTimeSlots = computeDeliveryTimeSlots({
    deliveryDate: userBasketSettingsState.settings.value.date
      ? new Date(userBasketSettingsState.settings.value.date)
      : null,
    minAllowedDateTime: minAllowedDeliveryDate,
    useDeliveryTimes: cutOff5pm,
  })

  return {
    userBasketSettings: userBasketSettingsState.settings,
    minAllowedDeliveryDate,
    deliveryTimeSlots,
  }
}
