import React, { useState } from 'react'
import { TagGroupNames, VendorSearchState } from '../../states/vendor-list'
import { useTagsQuery } from '@/generated/graphql'

import Box from '@caterdesk/ui--box'
import { CollapseWrapper, BoxWrapper } from '../index.styles'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Collapse,
  Paper,
  Stack,
  Typography,
  FormControl,
  ButtonBase,
} from '@mui/material'
import { Add, Remove } from '@mui/icons-material'
import { getCountryFromLocale } from '@/helpers/multiRegion'
import { useLocale } from '@/components/Link'
import useTenant from '@/hooks/useTenant'

type Props = {
  tagType: TagGroupNames
  defaultOpen: boolean
  useName?: boolean
  orderBy?: 'position' | 'label'
  specificToCountry?: boolean
  stats: VendorSearchState['stats']
  search: VendorSearchState['search']
  filters: VendorSearchState['filters']
  searching: boolean
}

const TagFilters: React.FC<Props> = ({
  tagType,
  defaultOpen,
  useName = false,
  orderBy,
  specificToCountry,
  stats,
  search,
  filters,
  searching,
}) => {
  const tenant = useTenant()
  const locale = useLocale()
  const countryCode = getCountryFromLocale(locale || '')
  const defaultValue = filters[tagType.key] as string[]
  const [expanded, setExpanded] = useState(defaultOpen || false)
  const { data, fetchMore } = useTagsQuery({
    variables: {
      filters: {
        groupId: tagType.id,
        tenantId: tenant.id,
        ...(specificToCountry && {
          countryCode,
          isPublicFilterGm: true,
        }),
      },
      limit: 5,
      offset: 0,
      orderBy: orderBy || null,
    },
  })
  const tagFilter = new Set(defaultValue || [])
  const setNewTagFilter = (newTagFilter: Set<string>) => {
    void search({ [tagType.key]: Array.from(newTagFilter) })
  }

  const tags = data?.tags.rows || []

  const showMore = () => {
    void fetchMore({
      variables: {
        filters: {
          groupId: tagType.id,
          tenantId: tenant.id,
          ...(specificToCountry && {
            countryCode,
            isPublicFilterGm: true,
          }),
        },
        limit: 200,
        offset: tags?.length,
        orderBy: orderBy || null,
      },
    })
  }

  let filterLabel = tagType.label
  const filtersTotal = Array.from(tagFilter).length
  if (filtersTotal > 0) filterLabel += ` (${filtersTotal})`
  const filterStats = stats ? stats[tagType.key] : null

  return (
    <CollapseWrapper>
      <ButtonBase sx={{ width: '100%' }} onClick={() => setExpanded((prev) => !prev)}>
        <Paper
          elevation={0}
          sx={{ width: '100%', backgroundColor: 'primary.main', color: 'inverted.main' }}
        >
          <Stack
            alignItems="center"
            alignContent="center"
            width="100%"
            color="inverted.main"
            direction="row"
            justifyContent="space-between"
            padding={1}
          >
            <Typography color="inverted.main">{filterLabel}</Typography>
            {!expanded ? (
              <Add sx={{ color: 'inverted.main' }} />
            ) : (
              <Remove sx={{ color: 'inverted.main' }} />
            )}
          </Stack>
        </Paper>
      </ButtonBase>
      <Collapse in={expanded} timeout="auto">
        <BoxWrapper>
          <Box backgroundColor="canvas" padding={{ x: 16, y: 8 }}>
            {tags &&
              tags.map((tag) => {
                const keyType = useName && tag.name ? 'name' : 'label'
                const title = tag[keyType]
                if (!title || !tag.groupId) return null
                const filterStatsCount = filterStats?.find(
                  (tagId) => tagId[keyType] === title,
                )?.count
                return filterStatsCount ? (
                  <FormControl fullWidth key={`${tag.id}-${tag.label}`}>
                    <FormControlLabel
                      label={`${title} (${filterStatsCount})`}
                      control={
                        <Checkbox
                          checked={Boolean(tagFilter.has(title))}
                          onChange={(_, checked) => {
                            const newSet = new Set(tagFilter)
                            if (checked) newSet.add(title || '')
                            else newSet.delete(title || '')
                            setNewTagFilter(newSet)
                          }}
                          name={tag.groupId}
                        />
                      }
                    />
                  </FormControl>
                ) : null
              })}
            {data?.tags.pageInfo.hasMore && !searching && (
              <Button fullWidth onClick={() => showMore()}>
                Show more
              </Button>
            )}
          </Box>
        </BoxWrapper>
      </Collapse>
    </CollapseWrapper>
  )
}

export default TagFilters
